import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { CompanyDetailsService } from '../api-service/companyDetails/companyDetails.service';
import { HeadingtitleService } from '../headingtitle.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
    selector: 'app-bp-invite-accepts',
    template: `

        <div *ngIf="loadingSite" class="h-100 w-100">
            <div class="d-flex align-content-stretch" style="justify-content: center;align-items:center;height:100vh;" >
                <div class="spinner-border" role="status" >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!loadingSite">
            <ngx-loading-bar [color]="'blue'"></ngx-loading-bar>

            <div *ngIf="!isConnected" >
                <app-overlay-model [siteSettings]="siteSettings"></app-overlay-model>
            </div>

            <app-carousel-slide [slides]="slides"></app-carousel-slide>

            <div nz-row [nzGutter]="[2]" style="position:absolute;top:0;left:0;z-index:1;">
                <div nz-col [nzSpan]="24" *ngIf="siteSettings[0]?.siteLogoLarge != null">
                    <img src="../../assets/img/{{siteSettings[0]?.siteLogoLarge}}" [ngStyle]="{'width': siteSettings[0]?.domainName === 'grcarapp.com' ? '25%':'40%', 'padding':'15px' }" />
                </div>
            </div>

            <div class="container-fluid contentSection" style="padding:0px !important;">    
                <div nz-row [nzGutter]="[2]" nzJustify="space-around" nzAlign="middle" style="height:100vh;">
                    <div nz-col nzSpan="6" >
                        <nz-card [nzBordered]="false" class="text-center logoContainer ">
                            <ng-container *ngIf="checkTokenCondition">
                                <button nz-button nzType="primary" (click)="statusChange('Accepted')">Accept Invite</button>
                                <button nz-button nzType="primary" nzDanger (click)="statusChange('Rejected')">Reject Invite</button>
                            </ng-container>
                            <ng-container *ngIf="!checkTokenCondition">
                                <h2 class="font-600">Sorry, your token has expired.</h2>
                                <h5 class="font-400">Please Contact {{siteSettings[0]?.applicationName === null ? 'Grcarapp':siteSettings[0]?.applicationName}} Support Team at support&#64;{{siteSettings[0]?.domainName}}</h5>
                            </ng-container>
                        </nz-card>
                    </div>
                </div>
            </div>

            <app-footer [siteSettings]="siteSettings"></app-footer>

        </ng-container>

    `,
    styles: [`

        /* Content Section */
        .contentSection{position:absolute;top:0;left:0;right:0;width:100%;z-index:2;}
        .contentloginSection {overflow: hidden; background-color:rgba(0,0,0,0.4);height:100vh;position:relative;display:flex;flex-direction:row;flex-grow:1;overflow-x:hidden;align-items:center !important;}
        .contentloginSection .loginContainer{position:relative;min-height:255px;margin:0px 15px;width:100%;}
        .contentloginSection .loginLogo{box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);z-index: 2;position: absolute;top: -38px;left: 10px;width: 60px;height: 60px;padding-right: 13px;border-radius: 50%;padding: 10px;background-color: #fff}
        .contentloginSection .loginLogo img {width:96%;margin-top:2px;}

        .contentloginSection .loginForm{padding:20px;background:#fff;}
        .contentloginSection .loginTxt{font-size:20px;color:#000;border-bottom:1px solid #cbd5dd;margin-top:3px;margin-bottom:8px;padding-bottom:5px;font-weight: 600;}
        .contentloginSection .loginDesc{font-size:13px;color:#7d7d7d;margin-bottom:15px;font-weight: 400;}
        .contentloginSection .form-group{font-size: 20px;margin-bottom:15px;}
        .contentloginSection .form-control{height: 45px;font-size: 18px;}
        .contentloginSection .labelforgot {font-size: 12px;font-weight: 500; color:#333;}
        /***************/
        .contentloginSection .loginBtn{border-radius: 0px;font-size:20px;background:#2196f3;color:#fff;margin: 0 auto;min-height: 20px;padding:11px;text-align: center;position: relative;border-bottom: 5px solid #1e87da;cursor:pointer;}
        .contentloginSection .googleBtn img {width:27px;}
        .contentloginSection .tapestryBtn img {width:66px;}
        .contentloginSection .socialBtn{margin:5px 0px 0px 0px; border-radius: 0px;font-size:18px;background:#fff;color:#000;min-height: 20px;padding:11px;text-align: center;position: relative;cursor:pointer;border-bottom: 5px solid transparent;}
        .contentloginSection .googleBtn{background:#fff;border-bottom: 5px solid transparent;border-image: linear-gradient(120deg, #4285f4, #34a853, #fbbc05, #ea4335);border-image-slice: 1;}
        .contentloginSection .tapestryBtn{background:#ffdd00;border-bottom: 5px solid #c6ab00;}
        /**************/
        .contentloginSection .fPass{font-size:12px;color:#fcfcfc;cursor:pointer;float: right;text-transform: uppercase;font-weight: 500;margin-top:8px;}
        .contentloginSection .fPass:hover{color:#2196f3;}
        .contentloginSection .bckTolog{color: #fff;font-size:13px;font-weight: 400;margin-top:5px;cursor: pointer;}
        .contentloginSection .bckTolog:hover{color: #2196f3;}
        .contentloginSection .bckTolog i{font-size:10px;}
        .contentloginSection .bckTolog i.icon-lock{font-size:12px;}
        /************/
        .contentloginSection .verifyUser {width:100%;text-align: center;}
        .contentloginSection .verifyUser img {width:80px;}
        .contentloginSection .verifyUser h3, .contentloginSection .verifyUserpw h3 {font-size: 15px;font-weight: 600;text-transform: uppercase;margin-top: 10px;margin-bottom:-5px;}
        .contentloginSection .verifyUser h6, .contentloginSection .verifyUserpw h6 {font-size: 12px;font-weight: 400;color:#717171}

        .contentloginSection .verifyUserpw {width:100%;text-align:center;}
        .contentloginSection .verifyUserpw img {width:45px;}
        .contentloginSection .verifyUserpw .verifyUserpwcont {margin-left:50px;}

    `],
    
})


export class BpInviteAcceptsComponent implements OnInit {

    public loadingSite: boolean = false; public isConnected = true;  public companyname: string; public evnname: string; public hostname: string;
    public siteSettings: Array<any> = []; public slides: Array<any> = [];
    public ExpiredDaysMsg: number = 10;

    // password Show
    public setpwShow: boolean; public displayComplianceCart:boolean = false;
    public is_verifyload: boolean = false;
    public bckTolog: boolean = false;
    public checkTokenCondition: boolean = false;

    // String
    public token: string;
    public imagePath: string;

    // Object
    public userData: any = []; public vendor: any[];


    constructor(
        private companydetailsService: CompanyDetailsService,
        private _Activatedroute: ActivatedRoute,
        private connectionService: ConnectionService,
        private headingtitleService: HeadingtitleService,
        private notification: NzNotificationService,
    ) {
        this.loadingSite = true;
        this.connectionService.monitor().subscribe((currentState: ConnectionState) => {
            this.isConnected = currentState.hasNetworkConnection && currentState.hasInternetAccess;
        });
    }

    ngOnInit() {

        this.companyname = localStorage.getItem('companyname');
        this.evnname = localStorage.getItem('evnname');
        this.hostname = localStorage.getItem('hostname');

        this.siteSettings = [JSON.parse(localStorage.getItem("siteSettings"))];
        if (this.siteSettings[0].backgroundSliderImg !== null) {
            let tempSlide = this.siteSettings[0].backgroundSliderImg.split(',');
            tempSlide.forEach(e => {
                let imgUrl = this.headingtitleService.getImagesS3('slider', e.trim(), false);
                this.slides.push({ img: imgUrl });
            });
        }

        this.token = this._Activatedroute.snapshot.paramMap.get('t');
        this.verifyToken(this._Activatedroute.snapshot.paramMap.get('t'));

        setTimeout(() => {
            this.loadingSite = false;
        }, 1000);

    }

    // verify token before reset password in background Process
    public verifyToken(token:string):void {
        if (token != '') {
            this.is_verifyload = true;
            this.companydetailsService.verifyVendorToken({ "vendorToken": token }).subscribe(res => {
                if (res['success'] === true) {
                    this.is_verifyload = false; this.checkTokenCondition = true;
                    this.vendor = res['data'];
                } else {
                    this.is_verifyload = false; this.checkTokenCondition = false;                
                }
            });
        }
    }

    public statusChange(statename:string):void {
        this.companydetailsService.updateStatusVendors({
            "vendorStatusId": statename === 'Accepted' ? 3:6,
            "vendorUserId": this.vendor[0].vendorUserId,
            "deletedAt": this.headingtitleService.getFormattedTimeStamp()
        }).subscribe(res => {
            if (res['success'] === true) {
                const settingData = JSON.parse(localStorage.getItem('siteSettings'));
                if (statename === 'Accepted') {
                    this.notification.create('info', 'Thank You for Accepting Invite !', `If you have any Query. Please contact ${settingData.applicationName === null ? 'Grcarapp':settingData.applicationName} Support Team at support@${settingData.domainName}`, { nzPlacement: 'topRight' });
                } else {
                    this.notification.create('info', 'Thank You for Rejecting Invite !', `If you have any Query. Please contact ${settingData.applicationName === null ? 'Grcarapp':settingData.applicationName} Support Team at support@${settingData.domainName}`, { nzPlacement: 'topRight' });
                }
            } else {
                this.notification.create('error', 'Business Partner !',  res['message'], { nzPlacement: 'topRight' });
            }
        });
    }
    


}
