
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Third Party Plugin
//import { Ng2SearchPipeModule } from 'ng2-search-filter'; // Search Filter Pipe
import { CustomFilterPipeFix } from './custom-filter-pipe.pipe';

//import { FilterPipeModule } from 'ngx-filter-pipe'; // Search Filter Pipe
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; // Bootstrap Feature Tab, Button, etc
import { NgxPaginationModule } from 'ngx-pagination';  // Table Pagination
import { OrderModule } from 'ngx-order-pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel'; // SLider for Login, Reset Password, Ticket
//import { NgxMapboxGLModule } from 'ngx-mapbox-gl'; // Mapbox for MKD, Store360
import { HighchartsChartModule } from 'highcharts-angular'; // Highcharts
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { NzUploadModule } from 'ng-zorro-antd/upload';


// Custom Pipe
import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { SplitsPipe, SplitsTagPipe } from './splits/splits.pipe';
import { CustomFilterPipe, GrdFilterPipe, ColumnFilterPipe } from './filters/customfilter.pipe';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number/two-digit-decimal-number.directive';
import { WeatherPipe, UvindexPipe, TempConverterPipe } from './weather/weather.pipe';
import { DateformatPipe, LastDateTimeformatPipe, EventDateformatPipe } from './date/dateformat.pipe';
import { DragDropDirective } from './drag-drop/drag-drop.directive'; // File Drag & Drop Images, Doc
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TimeMaskDirective } from './directive/time-mask.directive';

// Custom Services
import { CsvServices } from './services/csv-services';
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedService } from './services/shared-services';

import { NzTreeModule } from 'ng-zorro-antd/tree';
@NgModule({
  imports: [
  ],
  declarations: [
    TimeAgoPipe, TempConverterPipe, WeatherPipe, UvindexPipe, SplitsPipe, SplitsTagPipe, DateformatPipe, EventDateformatPipe, LastDateTimeformatPipe,
    CustomFilterPipe, GrdFilterPipe, DragDropDirective, ColumnFilterPipe,TwoDigitDecimalNumberDirective, TimeMaskDirective, CustomFilterPipeFix
  ],
  exports: [
    TimeMaskDirective,
    TwoDigitDecimalNumberDirective,
    ColumnFilterPipe,
    TimeAgoPipe,
    TempConverterPipe,
    WeatherPipe,
    UvindexPipe,
    SplitsPipe,
    SplitsTagPipe,
    DateformatPipe,
    EventDateformatPipe,
    LastDateTimeformatPipe,
    /********************** */
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //FilterPipeModule,
    NgbModule,
    NgxPaginationModule,
    //NgxMapboxGLModule,
    SlickCarouselModule,
    OrderModule,
    CustomFilterPipe,
    GrdFilterPipe,
    DragDropDirective,
    NzTabsModule,
    HighchartsChartModule,
    PdfViewerModule,
    DragDropModule,
    NzIconModule,
    NzLayoutModule,
    NzCardModule,
    NzGridModule,
    NzRadioModule,
    NzSpinModule,
    NzTableModule,
    NzCheckboxModule,
    NzSwitchModule,
    NzSelectModule,
    NzInputModule,
    NzModalModule,
    NzDrawerModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzButtonModule,
    NzToolTipModule,
    NzDividerModule,
    NzDropDownModule,
    NzMenuModule,
    NzEmptyModule,
    NzFormModule,
    NzSpaceModule,
    NzListModule,
    NzPaginationModule,
    NzBadgeModule,
    NzNotificationModule,
    NzStatisticModule,
    NzTagModule,
    NzBreadCrumbModule,
    NzTimePickerModule,
    NzInputNumberModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzAvatarModule,
    NzSkeletonModule,
    NzCarouselModule,
    NzTransferModule,
    NzTreeViewModule,
    NzRateModule,
    NzProgressModule,
    NzResultModule,
    NzImageModule,
    NzPageHeaderModule,
    NzAutocompleteModule,
    NzDescriptionsModule,
    NzMessageModule,
    NzResizableModule,
    NgApexchartsModule,
    NzTreeModule,
    NzUploadModule,
    CustomFilterPipeFix
  ],
  providers: [
    //Ng2SearchPipeModule,
    CurrencyPipe,
    CsvServices,
    SharedService
  ]
})

export class SharedModule { }
