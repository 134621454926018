
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { CompanyDetailsService } from '../../api-service/companyDetails/companyDetails.service';
import { LoginService } from './../../api-service/login-service/login.service';
import { TicketService } from '../../api-service/ticket/ticket.service';
import { ThemeService } from '../../api-service/services-theme/theme.service';
import { HeadingtitleService } from '../../headingtitle.service';
import * as CryptoJS from 'crypto-js';
import { NgxFreshChatService } from 'ngx-freshchat';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-default-layout',
    templateUrl: 'default-layout.component.html',
    styleUrls: ['default-layout.component.less']
})

export class DefaultLayoutComponent implements OnInit {

    public loadingSite: boolean = false;
    // isCollapsed = false; public companyList: any = [];
    public hostname: string; public hamActive: boolean = true; public userImgUrl: string; public isConnected = true;
    public customerLogo: string; public trialVersionDate: number = 0; public isTraining: boolean = false; public title_1: string = '';
    public title_2: string = ''; public appExpiredCount: number; public roleId: number; public applicationId: number;
    public environment = environment.ENVIRONMENT.title; public theme:string = ''; public showCustomerPortal:boolean = false;
    public showExp: boolean = false; public vendorPlan:boolean = false; public planCode:string = ''; public selectedItem: string;
    public stripeCustomerId:string = ''; public vCompAppId = 0; public planType:string = '';  public stripeProducts:any = [];
    public roleName: string = ''; 
    
    // Objects
    public userdetails: any = []; public applications: any = []; public applicationslist: any = []; public connection: any = [];
    public Menulist: Array<any> = []; public applicationSubscription: any = []; public groupData: Array<any> = [];
    public siteSettings: Array<any> = [];  public partnerCompany: Array<any> = []; public TempMenulist: Array<any> = [];
    
    // menu modeltype droplist
    public showmt: boolean = false;
    public switchSetupMenu: boolean = false;
    public isVisible: boolean = false;
    public showCompanyModal: boolean = false;
    appsToShow: any;
    public selectedCompany: string;
    public SearchPartnerCompany: string;

    public vendorList: Array<any> = []; 

    constructor(
        private router: Router,
        private headingtitleService: HeadingtitleService,
        private companyDetailsService: CompanyDetailsService,
        private ticketService: TicketService,
        private connectionService: ConnectionService,
        private loginService: LoginService,
        private themeService: ThemeService,
        private chat: NgxFreshChatService
    ) {
        this.loadingSite = true;
        this.connectionService.monitor().subscribe((currentState: ConnectionState) => {
            this.isConnected = currentState.hasNetworkConnection && currentState.hasInternetAccess;
        });
    }

    ngOnInit() {
        
        this.siteSettings = [JSON.parse(localStorage.getItem("siteSettings"))];

        // this.getAllApplicationList(); // get all application list
        
        this.headingtitleService.title_1.subscribe(res => {
            this.title_1 = res;
        });
        this.headingtitleService.title_2.subscribe(res => {
            this.title_2 = res;
        });

        this.vendorList = this.companyDetailsService.vendorList() as any;

        setTimeout(() => {
            let loginDetails = this.headingtitleService.getLoginData();
            if (!(loginDetails && loginDetails != undefined && loginDetails != null)) {
                this.router.navigate(["logout"]);
            } else {
                loginDetails = JSON.parse(loginDetails);
                this.userdetails = loginDetails[0];
                localStorage.setItem("partnerDataDomain", this.userdetails.siteURL);
                // console.log(this.userdetails);
                // this.applicationSubscription = loginDetails[0]['subscription'];
                this.userImgUrl = this.headingtitleService.getImagesS3(this.userdetails.siteURL + '/profile', loginDetails[0].imgUrl, true);
                
                // Get Basic Company Logo, trial period etc
                this.getCompanyDetails(loginDetails[0]['companyId']);
                
                // Get Login company roles and applications list
                //this.getApplicationList(loginDetails[0]['roleId'], loginDetails[0]['companyId'], loginDetails[0]['role_name']); //don't need this in hs?
                
                if (loginDetails[0]['partnerCompany'].length > 0 && this.siteSettings[0]?.domainName === 'grcarapp.com') {
                    this.loadingSite = false;
                    this.showCompanyModal = true;
                } else {
                    this.loadingSite = false;
                }
                this.selectedCompany = localStorage.getItem("partnerDataDomain");
            }
        }, 3000);

        this.headingtitleService.dayCount.subscribe(res => {
            if (res != 0 || res > 0) {
                this.showExp = true;
                this.appExpiredCount = res;
            } else {
                this.showExp = false;
            }
        });

        this.chat.init({
            token: '868c225a-17bf-48ab-9857-80313de904cc',
            host: 'https://wchat.freshchat.com',
            config: {
                headerProperty: {
                    hideChatButton: true
                }
            }
        }).subscribe();

    }

    public switchCompany() {
        this.router.navigate(['dashboard']);
        this.loadingSite = true;
        const rowData = this.userdetails.partnerCompany.filter(x => x.partnerCompanySiteUrl === this.selectedCompany)[0];
        if (rowData === undefined) {
            localStorage.setItem("partnerDataDomain", this.userdetails.siteURL);
            localStorage.setItem("partnerId", '');
            this.selectedCompany = localStorage.getItem("partnerDataDomain");
            this.getApplicationList(this.userdetails.roleId, this.userdetails.companyId, this.userdetails.role_name);
        } else {
            localStorage.setItem("partnerDataDomain", rowData['partnerCompanySiteUrl']);
            localStorage.setItem("partnerId", rowData['connectionPartnerId']);
            this.selectedCompany = localStorage.getItem("partnerDataDomain");
            this.getApplicationList(rowData['roleIds'], this.userdetails.companyId, rowData['roleNames']);
        }
        
        setTimeout(() => {
            this.loadingSite = false;
        }, 2000);
    }

    public getPartnerData(rowData: string) {
        localStorage.setItem("partnerDataDomain", rowData['partnerCompanySiteUrl']);
        localStorage.setItem("partnerId", rowData['connectionPartnerId']);
        this.selectedCompany = localStorage.getItem("partnerDataDomain");
        this.showCompanyModal = false;
        this.getApplicationList(rowData['roleIds'], this.userdetails.companyId, rowData['roleNames']);
        setTimeout(() => {
            this.loadingSite = false;
        }, 2000);
    }

    /**********************************************************************************/

    // THis is use for Customer portal
    private getVCompApplication() {
        this.headingtitleService.getApplicationListByRouteName('v-compliance').then(async res => {
            this.vCompAppId = res;
        });
    }

    public customerPortalClicked():void {
        this.getCustomerProducts();
    }

    private getCustomerProducts() {
        let products = []; let filteredProducts = []; let uniqueProducts = [];
        this.stripeProducts = [];
        let loginDetails = this.headingtitleService.getLoginData();
        if (!(loginDetails === undefined || loginDetails === null)) {
            loginDetails = JSON.parse(loginDetails);
            this.stripeCustomerId = loginDetails[0].stripeCustomerId;
            this.planType = loginDetails[0].subscription.find(x => x.applicationId == this.vCompAppId)?.productDetails[0].planType;
            this.companyDetailsService.getStripeProducts('v-compliance').subscribe(res => {
                if (res['success']) {
                    products = res['data'];
                    if (this.planType == 'customer') {
                        filteredProducts = products.filter(x => x.planType == 'customer');
                    } else {
                        filteredProducts = products;
                    }
                    uniqueProducts = [...new Set(filteredProducts.map(item => item.stripeProductId))];
                    uniqueProducts.forEach(productId => {
                        let obj = {
                            product: productId,
                            prices: filteredProducts.filter(x => x.stripeProductId == productId).map(x => x.stripePriceId)
                        }
                        this.stripeProducts.push(obj);
                    });
                }
                this.openCustomerPortal();
            });
        }
    }

    private openCustomerPortal() {
        let companyURL = localStorage.getItem('companydomain');
        let prevUrl = localStorage.getItem('previousUrl');
        let portalObject = this.configurePortalObject();
        this.companyDetailsService.getCustomerPortal(portalObject).subscribe(res => {
            if (res['success']) {
                const configId = res['data'].id;
                let data = {
                    "module": "billingPortal",
                    "title": "sessions",
                    "functionName": "create",
                    "data": {
                        customer: this.stripeCustomerId,
                        return_url: companyURL + prevUrl,
                        configuration: configId
                    }
                };
                this.companyDetailsService.getCustomerPortal(data).subscribe(res => {
                    if (res['success']) {
                        window.open(res['data'].url, '_blank');
                    } else {
                        console.log(res['message']);
                    }
                })
            } else {
                console.log(res['message']);
            }
        });
    }

    private configurePortalObject() {
        return {
            module: 'billingPortal',
            title: 'configurations',
            functionName: 'create',
            data: {
                features: {
                    customer_update: {
                        allowed_updates: ['address'],
                        enabled: true,
                    },
                    payment_method_update: {
                        enabled: true
                    },
                    subscription_cancel: {
                        enabled: true,
                        options: [],
                        mode: "at_period_end"
                    },
                    subscription_update: {
                        enabled: true,
                        products: this.stripeProducts,
                        default_allowed_updates: ['quantity', 'price']
                    },
                    invoice_history: { enabled: true },
                },
                business_profile: {
                    privacy_policy_url: this.siteSettings[0]?.privacyPolicyUrl,
                    terms_of_service_url: this.siteSettings[0]?.termServiceUrl,
                    headline: 'Customer Portal'
                }
            }
        };
    }

    /********************************************************************************* */

    // For Customer portal  
    private getApplication(applicationRoute: string) {
        if (applicationRoute === 'hourstock') {
            let tabData = [];
            console.log('getting groups from new API in default-layout')
            this.headingtitleService.getGroupsHourstock(JSON.parse(this.headingtitleService.getLoginData())[0]['roleId']).then(x => {
                this.groupData = x;
                console.log(this.groupData)
                if (this.groupData != null && this.groupData?.find(x => x.fieldName == 'Billing Information') !== null) {
                    this.showCustomerPortal = true;
                }
            });
        } else {
            console.log(`asking for getApplicationListByRouteName in headingtitle.service from default`);
            this.headingtitleService.getApplicationListByRouteName(applicationRoute).then(res => {
                this.applicationId = res;
                this.headingtitleService.getGroups(JSON.parse(this.headingtitleService.getLoginData())[0]['roleId'], this.applicationId).then(x => {
                    this.groupData = x;
                    console.log(this.groupData)
                    if (this.groupData != null && this.groupData?.find(x => x.fieldName == 'Billing Information') !== null) {
                        this.showCustomerPortal = true;
                    }
                });
            });
        }
    }

    // Get All APplication List from Backoffice
    // private getAllApplicationList(): void {
    //     this.companyDetailsService.getApplicationList().subscribe(res => {
    //         if (res['success'] === true) {
    //             this.applicationslist = res['data'];
    //         }
    //     });
    // }

    // Get Connection, Subscription List of Company
    private getCompanyDetails(companyId: number) {
        this.companyDetailsService.getCompany(companyId).subscribe((res: any) => {
            if (res['success'] === true) {
                this.planCode = res.data.pabblyPlanCode;
                this.changeTheme(res.data.theme);
                if (res['data']['fileURL'] != null) {
                    this.customerLogo = this.headingtitleService.getImagesS3(this.userdetails.siteURL + '/customerlogo', res['data']['fileURL'], true);
                } else {
                    this.customerLogo = null;
                }
                if (localStorage.getItem('evnname') === 'training') {
                    this.isTraining = res['data']['training'] === 1 ? true : false;
                    this.trialVersionDate = res['data']['training'] === 1 ? this.headingtitleService.showTrialDays(res['data']['trialversionExpiryDate']) : 0;
                }
            }
        });
    }

    // Subscription & expiry Days Count
    public showTrialDays(fDate: any) {
        let futureDate = new Date(fDate); // future date (coming from backoffice company setup)
        let currentDate = new Date(); // today current date
        let Difference_In_Time = futureDate.getTime() - currentDate.getTime(); // To calculate the time difference of two dates
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates
        return (Math.round(Difference_In_Days) === 0 || Math.round(Difference_In_Days) < 0) ? 0 : Math.round(Difference_In_Days); // Return remaining days Count  // If the days is 0 then it is 0 but if the day is less then 0 (-10) then it return to 0
    }

    // Get All Application List of Company
    // private getApplicationList(roleId: number) {
    //     this.companyDetailsService.getRoles(roleId).subscribe((res: any) => {
    //         if (res['success'] === true) {
    //             let evnName = localStorage.getItem('evnname');
    //             res['data'][0]['tabs'].forEach(r => {
    //                 if ((r.dev === 1 && evnName === 'dev') || (r.qa === 1 && evnName === 'qa') || (r.prod === 1 && evnName === 'prod') || (r.training === 1 && evnName === 'training')) {
    //                     this.applications.push(r.applicationId);
    //                 }
    //             });
    //             this.applications = Array.from(new Set(this.applications));  // unique Array of application list in role
    //             this.applications.forEach(appSelid => {
    //                 if (this.applicationslist.filter(u => { if (Number(u.applicationId) === Number(appSelid)) { return u } })[0].applicationName != "User Setup") {
    //                     this.Menulist.push({
    //                         "applicationId": this.applicationslist.filter(u => { if (Number(u.applicationId) === Number(appSelid)) { return u } })[0].applicationId,
    //                         "applicationName": this.applicationslist.filter(u => { if (Number(u.applicationId) === Number(appSelid)) { return u } })[0].applicationName,
    //                         "icon": this.applicationslist.filter(u => { if (Number(u.applicationId) === Number(appSelid)) { return u } })[0].icon,
    //                         "router": this.applicationslist.filter(u => { if (Number(u.applicationId) === Number(appSelid)) { return u } })[0].router,
    //                         "expirydaysCount": this.applicationSubscription.filter(a => { if (Number(a.applicationId) === Number(appSelid)) { return a } })[0].expiryDate,
    //                         "subscription": this.applicationSubscription.filter(a => { if (Number(a.applicationId) === Number(appSelid)) { return a } })[0].subscription
    //                     });
    //                 }
    //             });
    //             this.TempMenulist = this.Menulist;
    //             this.businessPartnerVisibility();
    //             const appRoute = JSON.stringify(this.Menulist.filter(x => { if (x.subscription === 1) { return x.applicationId; } }).map(z => z.router));
    //             localStorage.setItem('selectedAppRoute', CryptoJS.AES.encrypt(appRoute, 'app-route').toString());
    //             localStorage.setItem('selectedApplication', this.Menulist.filter(x => { if (x.subscription === 1) { return x.applicationId; } }).map(z => { return z.applicationId; }) as any);
    //             // localStorage.setItem('selectedAppRoute', this.Menulist.filter(x => { if (x.subscription === 1) { return x.applicationId; } }).map(z => { return z.router; }) as any);
    //         }
    //     });
    // }

    // Get Subscription Application List by multiple Roles & Company
    private getApplicationList(roleId: any, companyId: number, roleName: string): void {
        this.companyDetailsService.getApplications(roleId, companyId).subscribe((res: any) => {
            if (res['success'] === true) {
                this.Menulist = res['data'].filter(x => x.applicationName != "User Setup");
                this.businessPartnerVisibility();
                const appRoute = JSON.stringify(this.Menulist.filter(x => { if (x.subscription === 1) { return x.applicationId; } }).map(z => z.router));
                this.roleName = roleName;
                localStorage.setItem('roleName', roleName);
                localStorage.setItem('selectedAppRoute', CryptoJS.AES.encrypt(appRoute, 'app-route').toString());
                localStorage.setItem('selectedApplication', this.Menulist.filter(x => { if (x.subscription === 1) { return x.applicationId; } }).map(z => { return z.applicationId; }) as any);
            } else {
                this.Menulist = [];
            }
        });
        this.getApplication('profile');
        //this.getVCompApplication();
    }

    private businessPartnerVisibility() {
        if (this.Menulist.find(x => x.applicationName === 'Business Partner') == null) {
            this.vendorPlan = true;
        }
    }

    /************************************************************************************** */

    public mouseHoverMenuList(e) {
        this.hamActive = !this.hamActive;
    }

    public mtMenuClick(): void {
        this.selectedItem = '';
        this.showmt = !this.showmt;
    }

    public mainMenuClick(event, newValue) {
        this.selectedItem = newValue;
        this.isVisible = false;
    }

    public getimg(filename) {
        return this.headingtitleService.getImagesS3(this.userdetails.siteURL + '/profile', filename, true);
    }

    public subMenuClick(newValue: string) {
        if (this.selectedItem === newValue) {
            this.selectedItem = '';
        } else {
            this.selectedItem = newValue;
        }
    }

    public changeTheme(theme) {
        switch (theme) {
            case 'red-theme':
                this.theme = 'red';
                break;
            case 'lightBlue-theme':
                this.theme = 'lightBlue';
                break;
            case 'darkBlack-theme':
                this.theme = 'grey';
                break;
            case 'purple-theme':
                this.theme = 'purple';
                break;
            default:
                this.theme = 'default';
        }
        this.themeService.toggleTheme(this.theme);
    }

    public openChat() {
        this.chat.open();
    }

    partnerCompanyLogo(siteurl, fileUrl) {
        return this.headingtitleService.getImagesS3(siteurl + '/customerlogo', fileUrl, true);
    }

}




