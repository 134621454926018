// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  ENVIRONMENT: {
    title: 'dev',
    baseURL: 'https://groupsoft.dev.grcarapp.com/api/', // AWS Server API
    //baseURL: 'http://127.0.0.1:8015/',
    weatherURL: 'https://groupsoft.dev.grcarapp.com/weather/', // Weather API      
    imageUrl: 'https://groupsoft.dev.grcarapp.com/api-images/',  //Aws Server Profile Image
    auth0ClientID: 'yIDtQRIowrwCRYc4NtZAxY5tnQ4c3yYy',
    auth0Domain: 'groupsoft.auth0.com',
    auth0Connection: 'akmcoachdev',
    audience : 'https://groupsoft.auth0.com',
    weatherkey:'d7268838f2e2adb9cceb6df1545b41f6/'
  }
};
