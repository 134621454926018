
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './layout';
import { P404Component } from './p404/p404.component';
import { P500Component } from './p500/p500.component';
import { LoginComponent } from './login/login.component';
import { CallbackComponent } from './callback.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { BpInviteAcceptsComponent } from './bp-invite-accepts/bp-invite-accepts.component';
import { AuthGuard } from './auth-guard/auth.guard';
import { ChildGuard } from './child-guard/child-guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '404', component: P404Component, data: { title: 'Page 404' } },
  { path: '500', component: P500Component, data: { title: 'Page 500' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'logout', component: LogoutComponent, data: { title: 'Logout' } },
  { path: 'reset_password/:t', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'set_password/:t', component: SetPasswordComponent, data: { title: 'Set Password' } },
  { path: 'verify_business_partner/:t', component: BpInviteAcceptsComponent, data: { title: 'Business Partner' } },
  { path: 'callback', component: CallbackComponent },
  {
    path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: '', loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule) },
      {
        path: 'dashboard', loadChildren: () => import('./views/dashboard/dashboard.module')
          .then(m => m.DashboardModule)
      },
      // {
      //   path: 'v-compliance', loadChildren: () => import('./views/vcompliance/vcompliance.module')
      //     .then(m => m.VcomplianceModule), canActivate: [ChildGuard]
      // },	  
      {
        path: 'hourstock', loadChildren: () => import('./views/hourstock/hourstock.module')
          .then(m => m.HourstockModule)
      },
      // {
      //   path: 'store360', loadChildren: () => import('./views/store360/store360.module')
      //     .then(m => m.Store360Module), canActivate: [ChildGuard]
      // },
      // {
      //   path: 'merchandise360', loadChildren: () => import('./views/m360/m360.module')
      //     .then(m => m.M360Module), canActivate: [ChildGuard]
      // },
      {
        path: 'modeltype', loadChildren: () => import('./views/modeltype/modeltype.module')
          .then(m => m.ModeltypeModule)
      },
      {
        path: 'merchandise-social', loadChildren: () => import('./views/m-social/m-social.module')
          .then(m => m.MSocialModule), canActivate: [ChildGuard]
      },
      // {
      //   path: 'assortment-performance', loadChildren: () => import('./views/assortmentperformance/assortmentperformance.module')
      //     .then(m => m.AssortmentperformanceModule), canActivate: [ChildGuard]
      // },
      {
        path: 'bp-connect', loadChildren: () => import('./views/bpconnect/bpconnect.module')
          .then(m => m.BpconnectModule), canActivate: [ChildGuard]
      },
      // {
      //   path: 'vm-analytics', loadChildren: () => import('./views/vmanalytics/vmanalytics.module')
      //     .then(m => m.VmanalyticsModule), canActivate: [ChildGuard]
      // },
      // {
      //   path: 'mkd-planning', loadChildren: () => import('./views/mkd-planning/mkd-planning.module')
      //     .then(m => m.MkdplanningModule), canActivate: [ChildGuard]
      // },
      // {
      //   path: 'vendor-performance', loadChildren: () => import('./views/vendor-performance/vendor-performance.module')
      //     .then(m => m.VendorPerformanceModule)
      // },
      // {
      //   path: 'retailcalendar', loadChildren: () => import('./views/retailcalender/retailcalender.module')
      //     .then(m => m.RetailcalenderModule), canActivate: [ChildGuard]
      // },
      /************************************************************************************************************************** */
      // {
      //   path: 'markdown-strategy', loadChildren: () => import('./views/markdown-strategy/markdown-strategy.module')
      //     .then(m => m.MarkdownStrategyModule)
      // },
      {
        path: 'business-partner', loadChildren: () => import('./business-partner/business-partner.module')
          .then(m => m.BusinessPartnerModule),  canActivate: [ChildGuard]
      },
      { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
      { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
      {
        path: 'non-subscription', loadChildren: () => import('./views/non-subscription/non-subscription.module')
          .then(m => m.NonSubscriptionModule)
      },
      // {
      //   path: 'assortment-performance-sf', loadChildren: () => import('./views/assortmentperformance-sf/assortmentperformance-sf.module')
      //     .then(m => m.AssortmentperformanceSFModule)
      // },
      { path: 'tickets', loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule) }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
