import { NgModule } from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { LocationStrategy, PathLocationStrategy , DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { IconsProviderModule } from './icons-provider.module';
import { AppInitializerProvider } from './api-service/services-theme/app-initializer.service';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';


/************************* Custom Plugin ***************************** */
import { AppRoutingModule } from './app-routing.module';
import { OrderPipe } from 'ngx-order-pipe';
import { SocialAuthService , SocialLoginModule, SocialAuthServiceConfig , GoogleLoginProvider, FacebookLoginProvider } from "@abacritt/angularx-social-login";
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CookieService } from 'ngx-cookie-service';
//import { DeviceDetectorModule } from 'ngx-device-detector';
import { DeviceDetectorService} from 'ngx-device-detector';
import { SharedModule } from './shared/shared.module';
import { SharedComponentsModule } from './shared/components/shared-components.module';
// import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxFreshChatModule } from 'ngx-freshchat';
/***************************************************************************************************/

// Import Containers
import { DefaultLayoutComponent } from './layout';
/********************/
import { AppComponent } from './app.component';
import { P404Component } from './p404/p404.component';
import { P500Component } from './p500/p500.component';
import { LoginComponent } from './login/login.component';
import { CallbackComponent } from './callback.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { BpInviteAcceptsComponent } from './bp-invite-accepts/bp-invite-accepts.component';
import { AuthGuard } from './auth-guard/auth.guard';
import { ChildGuard } from './child-guard/child-guard';
import { LoginService } from './api-service/login-service/login.service';
import { RequestAccessComponent } from './layout/request-access/request-access.component';
import { AppNotificationComponent } from './layout/app-notification/app-notification.component';

/***************************************************************************************************/

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    RequestAccessComponent,
    AppNotificationComponent,
    P404Component,
    P500Component,
    LoginComponent,
    CallbackComponent,
    LogoutComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    BpInviteAcceptsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    HttpClientModule,
    SharedModule,
    SharedComponentsModule,
    IconsProviderModule,
    //NgxMapboxGLModule.withConfig({ accessToken: 'pk.eyJ1IjoiYW5hbmRncyIsImEiOiJja2x2NWYwNWkxNjNkMm9wM2g2a21ycTc4In0.aX-DOS8JXSIyjtVd-PQ-yg' })
  ],
  providers: [
    { 
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '239634624935-ab3nc5jsmapqhpdk5kckbtq9c1m2kbrc.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    SocialAuthService,
    OrderPipe,
    DatePipe,
    CookieService,
    LoginService,
    AuthGuard,
    NgxFreshChatModule,
    DeviceDetectorService,
    ChildGuard,
    AppInitializerProvider,
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
  exports: []
})


export class AppModule { }
