import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from "@angular/common";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HeadingtitleService } from '../headingtitle.service';
import { LoginService } from '../api-service/login-service/login.service';
import { environment } from '../../environments/environment';
import * as auth0 from 'auth0-js';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.less'],
})
export class LoginComponent implements OnInit {

  public loadingSite: boolean = false;
  public isConnected: boolean = true;
  public pass: boolean = false;
  public passwordVisible: boolean = false;
  public trialVersionDate: number;
  public isTraining: boolean = false;
  public siteSettings: Array<any> = [];
  public companies: any = [];
  public slides: Array<any> = [];
  public deviceInfo: any;
  public siteClassName: string;

  public companyname: string;
  public evnname: string;
  public hostname: string;
  public ExpiredDaysMsg: number = 10;

  // password Show
  public pwShow: boolean;
  public is_forgotPwload: boolean = false;
  public is_forgotUUload: boolean = false;

  // Swipe default Value
  public swipeFormDiv: boolean = false;
  public GoogleLoginflag: boolean = false;

  // Auth0 Config
  auth0 = new auth0.WebAuth({
    clientID: environment.ENVIRONMENT.auth0ClientID,
    domain: environment.ENVIRONMENT.auth0Domain
  });

  // Form
  public loginForm: FormGroup;
  public forgotForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private authService: SocialAuthService,
    private loadingBar: LoadingBarService,
    private headingtitleService: HeadingtitleService,
    private connectionService: ConnectionService,
    private deviceService: DeviceDetectorService,
    private notification: NzNotificationService,
    private scroller: ViewportScroller,
  ) {
    this.loadingSite = true;
    this.connectionService.monitor().subscribe((currentState: ConnectionState) => {
      this.isConnected = currentState.hasNetworkConnection && currentState.hasInternetAccess;
    });
  }

  ngOnInit() {
    this.companyname = localStorage.getItem('companyname');
    this.evnname = localStorage.getItem('evnname');
    this.hostname = localStorage.getItem('hostname');
    this.siteClassName = this.hostname.split('.')[0];

    this.deviceInfo = this.deviceService.getDeviceInfo();

    this.siteSettings = [JSON.parse(localStorage.getItem("siteSettings"))];
    if (this.siteSettings[0].backgroundSliderImg !== null) {
      let tempSlide = this.siteSettings[0].backgroundSliderImg.split(',');
      tempSlide.forEach(e => {
        let imgUrl = this.headingtitleService.getImagesS3('slider', e.trim(), false);
        this.slides.push({ img: imgUrl });
      });
    }

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });

    setTimeout(() => {
      this.loadingSite = false;
    }, 1000);
  }

  scrollTo(className: string): void {
    this.scroller.scrollToAnchor(className);
  }

  // click event function toggle
  public showPassword() {
    this.pwShow = !this.pwShow;
  }

  // Toggle Form
  public swipeForm() {
    this.swipeFormDiv = !this.swipeFormDiv;
    this.loginForm.reset();
    this.forgotForm.reset();
  }

  // Forgot Password form Validation
  public getforgotUser() {
    for (const key in this.forgotForm.controls) {
      this.forgotForm.controls[key].markAsDirty();
      this.forgotForm.controls[key].updateValueAndValidity();
    }
    if (!this.isTraining && (this.trialVersionDate != 0 || this.trialVersionDate < 0)) {
      this.is_forgotPwload = true;
      this.loadingBar.start();
      this.loginService.forgotPassword({ "email": this.forgotForm.value.email, "is_frontend_user": true, "domainName": this.hostname, "companyname": this.companyname, "isOpt": "password" }).subscribe(res => {
        if (res['success'] === true) {
          this.is_forgotPwload = false;
          this.notification.create('success', 'Recover Password !', 'Mail sent ' + this.forgotForm.value.email + '.', { nzPlacement: 'topRight' });
          this.forgotForm.reset();
        } else {
          this.is_forgotPwload = false;
          this.notification.create('error', 'Recover Password !', res['message'] + ' ' + this.forgotForm.value.email + '.', { nzPlacement: 'topRight' });
        }
      });
    } else {
      const hostName = JSON.parse(localStorage.getItem('siteSettings'));
      this.notification.create('info', 'Subscription expired', `Your Trial Subscription is expired. Please email or contact to support@${hostName.domainName}.`, { nzPlacement: 'topRight' });
    }
    this.loadingBar.complete();
  }

  // Forgot Username form Validation
  public getforgotUsername() {
    for (const key in this.forgotForm.controls) {
      this.forgotForm.controls[key].markAsDirty();
      this.forgotForm.controls[key].updateValueAndValidity();
    }
    if (!this.isTraining && (this.trialVersionDate != 0 || this.trialVersionDate < 0)) {
      this.is_forgotUUload = true;
      this.loadingBar.start();
      this.loginService.forgotPassword({ "email": this.forgotForm.value.email, "is_frontend_user": true, "domainName": this.hostname, "companyname": this.companyname, "isOpt": "username" }).subscribe(res => {
        if (res['success'] === true) {
          this.is_forgotUUload = false;
          this.notification.create('success', 'Recover Username !', 'Mail sent ' + this.forgotForm.value.email + '.', { nzPlacement: 'topRight' });
          this.forgotForm.reset();
        } else {
          this.is_forgotUUload = false;
          this.notification.create('error', 'Recover Username !', res['message'] + ' ' + this.forgotForm.value.email + '.', { nzPlacement: 'topRight' });
        }
      });
    } else {
      const hostName = JSON.parse(localStorage.getItem('siteSettings'));
      this.notification.create('info', 'Subscription expired', `Your Trial Subscription is expired. Please email or contact to support@${hostName.domainName}.`, { nzPlacement: 'topRight' });
    }
    this.loadingBar.complete();
  }

  // Login Form Validation
  public getloginUser() {
    for (const key in this.loginForm.controls) {
      this.loginForm.controls[key].markAsDirty();
      this.loginForm.controls[key].updateValueAndValidity();
    }
    console.log("in getLoginUser Component");
    if (!this.isTraining && (this.trialVersionDate != 0 || this.trialVersionDate < 0)) {
      console.log("in component, first if statement");
      this.loadingBar.start();
      this.loginService.loginUser(this.loginForm.value.username, this.loginForm.value.password, this.companyname, this.evnname).subscribe(res => {
        if (res['success'] === true) {
          if (res['token'] != '') {
            this.loginService.setAuthToken(res['token']); // Set Token
            // localStorage.setItem('timer', JSON.stringify(this.loginService.getTimeExpiredToken()));
            this.loginService.setLoginData(JSON.stringify(res['data'])); // Set Login Data
            this.createUserLogs('Login', 'Groupsoft', res['data'][0].roleId, res['data'][0].companyId, res['data'][0].id);
            this.loginForm.reset();
            this.notification.create('success', res['message'], res['data'][0].email, { nzPlacement: 'topRight' });
            setTimeout(() => {
              this.router.navigate(['dashboard']);
            }, 1500);
          }
        } else {
          this.notification.create('error', 'Failed', res['message'], { nzPlacement: 'topRight' });
        }
      });
    } else {
      console.log("in component, first else statement");
      const hostName = JSON.parse(localStorage.getItem('siteSettings'));
      this.notification.create('info', 'Subscription expired', `Your Trial Subscription is expired. Please email or contact to support@${hostName.domainName}.`, { nzPlacement: 'topRight' });
    }
    this.loadingBar.complete();
  }

  // Login With Google Account (User Should Exist in Mysql Database and When user click on google login it Verify on Email Id is exist or not)
  public signInWithGoogle() {
    if (!this.isTraining && (this.trialVersionDate != 0 || this.trialVersionDate < 0)) {
      this.loadingBar.start();
      // Fetching from Google
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
        this.GoogleLoginflag = true;
        // Finding Email Id in Our DB
        if (userData.email != '') {
          this.loginService.getUser(userData.email, this.companyname, this.evnname).subscribe(res => {
            if (res['success'] === true) {
              if (res['token'] != '') {
                this.loginService.setAuthToken(res['token']); // Set Token
                // localStorage.setItem('timer', JSON.stringify(this.loginService.getTimeExpiredToken()));
                this.loginService.setLoginData(JSON.stringify(res['data'])); // Set Login Data
                this.createUserLogs('Login', 'Google', res['data'][0].roleId, res['data'][0].companyId, res['data'][0].id);
                this.notification.create('success', res['message'], userData.email, { nzPlacement: 'topRight' });
                setTimeout(() => {
                  this.router.navigate(['dashboard']);
                }, 1500);
              }
            } else {
              this.notification.create('error', 'Failed', res['message'], { nzPlacement: 'topRight' });
              this.signOut();
            }
          });
        }
      }).finally(() => {
        this.loadingBar.complete();
        this.signOut();
        this.GoogleLoginflag = false;
      });
    } else {
      const hostName = JSON.parse(localStorage.getItem('siteSettings'));
      this.notification.create('info', 'Subscription expired', `Your Trial Subscription is expired. Please email or contact to support@${hostName.domainName}.`, { nzPlacement: 'topRight' });
    }
  }

  // Signout Google Account after login to Application
  private signOut(): void {
    this.authService.signOut();
  }

  // SSO Login With Auth0
  public getSamlloginUser(): void {
    if (!this.isTraining && (this.trialVersionDate != 0 || this.trialVersionDate < 0)) {
      this.loadingBar.start();
      this.auth0.authorize({
        clientID: environment.ENVIRONMENT.auth0ClientID,
        domain: environment.ENVIRONMENT.auth0Domain,
        audience: environment.ENVIRONMENT.audience + '/api/v2/',
        responseType: 'id_token token',
        connection: environment.ENVIRONMENT.auth0Connection,
        redirectUri: window.location.origin + '/callback?connection=' + environment.ENVIRONMENT.auth0Connection,
        scope: 'openid email profile'
      });
      this.loadingBar.complete();
    } else {
      const hostName = JSON.parse(localStorage.getItem('siteSettings'));
      this.notification.create('info', 'Subscription expired', `Your Trial Subscription is expired. Please email or contact to support@${hostName.domainName}.`, { nzPlacement: 'topRight' });
    }
  }

  private createUserLogs(action: string, loginwith: string, roleId: number, companyId: number, userId: number): void {
    let PostData = {
      "frontend_user_id": userId,
      "ip_address": JSON.parse(localStorage.getItem("ip")),
      "frontend_user_role_id": roleId,
      "frontend_user_company_id": companyId,
      "action": action,
      "login_with": loginwith,
      "description": "Login to Frontend Application",
      "browser_detail": this.deviceInfo.browser_version,
      "user_agent": this.deviceInfo.userAgent,
      "os": this.deviceInfo.os,
      "os_version": this.deviceInfo.os_version,
      "device": (this.deviceInfo.device != 'Unknown' || this.deviceInfo.device != 'unknown') ? this.deviceInfo.device : '-',
      "browser": this.deviceInfo.browser,
      "is_mobile": this.deviceService.isMobile() === true ? 1 : 0,
      "is_tablet": this.deviceService.isTablet() === true ? 1 : 0,
      "is_desktop": this.deviceService.isDesktop() === true ? 1 : 0
    };
    this.loginService.postUserLog(PostData).subscribe();
  }
}