import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';

// Shared Component
import { QuestionAnswerPreviewComponent } from './question-answer-preview/question-answer-preview.component';
import { LogsViewComponent } from './logs-view/logs-view.component';
import { InventoryViewComponent } from './inventory-view/inventory-view.component';
import { SplineChartComponent } from './spline-chart/spline-chart.component';
import { ColumnSplineChartComponent } from './column-spline-chart/column-spline-chart.component';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { HeatMapChartComponent } from './heat-map-chart/heat-map-chart.component';
import { AttachmentComponent } from './attachments/attachments.component';
import { DrilldownTreeComponent } from './drilldown-tree/drilldown-tree.component';
import { FooterComponent } from './footer/footer.component';
import { OverlayModelComponent } from './overlay-model/overlay-model.component';
import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';
import { NotfoundComponent } from './notfound/notfound.component';

// Aggrid Buttone Renderer
import { AttachmentsButtonRendererComponent } from './attachments/attachments-button.renderer';

@NgModule({
  imports: [
    SharedModule,
    AgGridModule
  ],
  exports: [
    SplineChartComponent,
    ColumnSplineChartComponent,
    ColumnChartComponent,
    PieChartComponent,
    HeatMapChartComponent,
    InventoryViewComponent,
    LogsViewComponent,
    QuestionAnswerPreviewComponent,
    AttachmentComponent,
    DrilldownTreeComponent,
    FooterComponent,
    OverlayModelComponent,
    CarouselSlideComponent,
    NotfoundComponent,
    DonutChartComponent
  ],
  declarations: [
    SplineChartComponent,
    ColumnSplineChartComponent,
    ColumnChartComponent,
    PieChartComponent,
    DonutChartComponent,
    HeatMapChartComponent,
    InventoryViewComponent,
    LogsViewComponent,
    QuestionAnswerPreviewComponent,
    AttachmentComponent,
    DrilldownTreeComponent,
    FooterComponent,
    OverlayModelComponent,
    CarouselSlideComponent,
    NotfoundComponent,
    AttachmentsButtonRendererComponent,
  ],
  providers: [],
})


export class SharedComponentsModule { }
