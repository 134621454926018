import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CompanyDetailsService } from './api-service/companyDetails/companyDetails.service';
import { DatePipe, DOCUMENT, formatDate } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { CommonService } from './api-service/common.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})


export class HeadingtitleService {

    public title_1: BehaviorSubject<string> = new BehaviorSubject('');
    public title_2: BehaviorSubject<string> = new BehaviorSubject('');
    public dayCount: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private datePipe: DatePipe,
    private companyService: CompanyDetailsService,
    private commonService: CommonService,
    private http: HttpClient
  ) { }

    setTitle1(title_1: string) {
        setTimeout(() => {
            this.title_1.next(title_1);
        }, 1000);
    }

    setTitle2(title_2: string) {
        setTimeout(() => {
            this.title_2.next(title_2);
        }, 1000);
    }

    // Each Application Expired Day Count
    setApplicationDayCount(count: number) {
        setTimeout(() => {
            this.dayCount.next(count);
        }, 1000);
    }

    // Custom Split with finding, position, provide data
    public getsplitValue(findstring: string, pos: string, data: string) {   // data
        return (pos != 'zero' ? data.toString().split(findstring)[1] : data.toString().split(findstring)[0]);
    }

    // For SSO login we split email and Username
    public getsamlEmailsplitValue(findstring: string, data: string) {
        return data.split(findstring)[2];
    }

    // this will remove only beginning and end of string whitespace.
    public trimming_Endpoints(x) {
        return x ? x.replace(/^\s+|\s+$/gm, '') : '';  // orginal
    }

    // this will remove whole whitespace from the string
    public trimming_Whole(x) {
        return x ? x.replace(/\s/g, '') : '';
    }

    // this will remove whole whitespace from the string
    public trimming_SpecialChar(x) {
        return x ? x.replace(/[^\w\s]/gi, '') : '';
    }

    public checkValidDateStr(str: string) {
        return str.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
    }

    // check Special Charatcer is preset or not
    public checkSpecialChar(str: string) {
        return str ? str.replace(/[^\w\s]/gi, '') : '';
    }


    private splittedDate: String[] = [];
    public formatDate(date: any) {
        // debugger
        if (date === undefined || date === '' || date === null || String(date).search(/[^a-zA-Z]+/) === -1) {
            return '';
        }
        if (date != '' || date != null) {
            if (typeof date === 'string' && date.length < 10) {
                this.splittedDate = String(date).split('-');
                return this.datePipe.transform(new Date([this.splittedDate[2], ((this.splittedDate[1].length < 2) ? ('0' + this.splittedDate[1]) : this.splittedDate[1]), ((this.splittedDate[0].length < 2) ? ('0' + this.splittedDate[0]) : this.splittedDate[0])].join('-')), "yyyy-MM-dd'T'HH:mm:ss.SSS");
            } else if (date.length === 10) {
                this.splittedDate = String(date).split('-');
                return this.datePipe.transform(new Date([this.splittedDate[2], this.splittedDate[1], this.splittedDate[0]].join('-')), "yyyy-MM-dd'T'HH:mm:ss.SSS");
            } else {
                return date;
            }
        } else {
            return '';
        }
    }

    // Subscription & expiry Days Count
    public showTrialDays(fDate: any) {
        let futureDate = new Date(fDate); // future date (coming from backoffice company setup)
        let currentDate = new Date(); // today current date
        let Difference_In_Time = futureDate.getTime() - currentDate.getTime(); // To calculate the time difference of two dates
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates
        return (Math.round(Difference_In_Days) === 0 || Math.round(Difference_In_Days) < 0) ? 0 : Math.round(Difference_In_Days); // Return remaining days Count
        // If the days is 0 then it is 0 but if the day is less then 0 (-10) then it return to 0
    }

    // Check Application by Route
    async getApplicationListByRouteName(routeName: string): Promise<number> {
        if (routeName == 'hourstock') {
            return 128 //hourstock id
        }
        return new Promise((resolve, reject) => {
            if (routeName != '') {
                console.log(`application: ${routeName}`)
                this.companyService.geApplicationByRoute(routeName).subscribe(res => {
                    if (res['success'] === true) {
                        // tslint:disable-next-line: no-string-literal
                        let app = res['data'].filter(u => u.router === routeName);
                        if (app != undefined && app.length > 0) {
                            if (localStorage.getItem('selectedApplication').includes(app[0].applicationId)) {
                                resolve(app[0].applicationId);
                            } else {
                                resolve(app[0].applicationId);
                            }
                        } else {
                            resolve(null);
                        }
                    } else {
                        reject(null);
                    }
                });
            } else {
                reject(null);
            }
        });
    }

    // Check Application by Route
    async getSubscription(applicationId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            if (applicationId != 0) {
                resolve(this.showTrialDays(JSON.parse(this.getLoginData())[0].subscription.filter(u => u.applicationId === applicationId)[0].expiryDate));
                // tslint:disable-next-line: max-line-length
                // resolve(this.showTrialDays(JSON.parse(this.getLoginData())[0]['subscription'].filter(u => u.applicationId === applicationId)[0].expiryDate));
            } else {
                reject(null);
            }
        });
    }

    //Get Tabs for hourstock
    async getGroupsHourstock(roleId: number): Promise<any> {
        if (roleId === 0) {
          throw new Error('Invalid roleId: roleId cannot be 0');
        }
      
        return new Promise((resolve, reject) => {
          this.companyService.getGroupFromRoles(roleId).subscribe({
            next: (tabs) => {
                let data = tabs["data"]
              resolve(data);
            },
            error: (err) => {
              reject(`Error retrieving tabs: ${err.message}`);
            },
          });
        });
    }  

    // Get Group List From RoleId & applicationId
    async getGroups(roleId: any, applicationId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            if (roleId != 0) {
                //console.log(`roleId: ${roleId}`);
                this.companyService.getRoles(roleId).subscribe(res => {
                    //console.log(`Inside getGroups`);
                    //console.log(res)
                    if (res['success'] === true) {
                        let groupData;
                        res['data'].forEach(t => {
                            console.log(`t (the role we are going through): ${t.name}`);
                            console.log(t)
                            let appRecord = t.tabs.filter(x => x.applicationId === Number(applicationId));
                            console.log(appRecord)
                            if (appRecord.length > 0) {
                                groupData = t.tabs.filter(u => u.applicationId === applicationId && (u.dev === 1 || u.qa === 1 || u.prod === 1 || u.training === 1));
                            }
                        });
                        if (groupData.length > 0) {
                            let groupIds = groupData.map(x => x.groupId).toString();
                            console.log(`groupids: ${groupIds}`)
                            this.companyService.getGroupByRole(groupIds).subscribe((res1: any) => {
                                console.log(`group data for groupId ${groupIds}:`)
                                console.log(res1)
                                if (res1.success === true) {
                                    let tabs = [];
                                    res1.data.forEach(element => {
                                        tabs.push(...element.tabs);
                                    });
                                    resolve(tabs);
                                }
                            });
                        } else {
                            reject(null);
                        }
                    } else {
                        reject(null);
                    }
                });
            } else {
                reject(null);
            }
        });
    }

    async generateBlankDates(calendarArray: any, typeCal: string, format: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (calendarArray) {
                let newcalendar = [];
                if (typeCal === 'Financial' || typeCal === 'Gregorian') {
                    for (let i = 0; i < calendarArray.length; i++) { // 12 month loop
                        newcalendar = calendarArray[i].calendar;
                        if (newcalendar[0].length > 0) { // First Array Concat|unshift Blank Value
                            let a = 7 - newcalendar[0].length;
                            for (let q = 0; q < a; q++) {
                                newcalendar[0].unshift({ 'weekN': null, 'day': null, 'month': null, 'year': null, 'mDate': null, 'format': null, 'today': null, 'event': null });
                            }
                        }
                        const lastIndex = newcalendar.length;
                        if (newcalendar[lastIndex - 1].length > 0) { // Last Array Push Blank value
                            const EmptyNumber = 7 - newcalendar[lastIndex - 1].length;
                            for (let p = 0; p < EmptyNumber; p++) {
                                newcalendar[lastIndex - 1].push({ 'weekN': null, 'day': null, 'month': null, 'year': null, 'mDate': null, 'format': null, 'today': null, 'event': null });
                            }
                        }
                    }
                    resolve(calendarArray);
                }
                if (typeCal === 'Retail') {
                    let tempMonth = []; let retailFormatArray = []; let count = 0; let index = 1;
                    retailFormatArray = format.split('-').map(Number);
                    for (let j = 0; j < calendarArray.length; j++) {
                        tempMonth.push({ col: index, month: calendarArray[j].month, year: calendarArray[j].year, current: calendarArray[j].current, calendar: calendarArray[j].calendar });
                        count += retailFormatArray[index - 1];
                        index += 1;
                        if (index === 4) {
                            index = 1;
                        }
                    }
                    resolve(tempMonth);
                }
            } else {
                reject(null);
            }
        });
    }

    // Create Date for CreatedDate, UpdatedDate, DeletedDate
    public getFormattedTimeStamp(): any {
        let date_ob = new Date();

        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);

        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

        // current year
        let year = date_ob.getFullYear();

        // current hours
        let hours = date_ob.getHours();

        // current minutes
        let minutes = date_ob.getMinutes();

        // current seconds
        let seconds = date_ob.getSeconds();

        // prints date & time in YYYY-MM-DD HH:MM:SS format
        return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
    }

    public getLoginData() {
        return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('loginData'), 'loginData').toString(CryptoJS.enc.Utf8));
    }

    public getImagesS3(folderName: string, imageName: string, isEnvironment: boolean) {
        if (imageName === null) {
            return './assets/img/users/default-profile.png';
        } else {
            return this.commonService.getAPI_Token().url + 's3Image?filter[where][token]=' + this.commonService.getAPI_Token().token + '&filter[where][filename]=' + imageName + '&filter[where][folder]=' + folderName + '&filter[where][isEnvironment]=' + isEnvironment
        }
    }

    // Get Color in Tne Status
    public tneStatusGetter(params) {
        if (params.data.statusName === 'In progress') {
            return { color: 'black' };
        } else if (params.data.statusName === 'Submitted') {
            return { color: '#4277d0' };
        } else if (params.data.statusName === 'Rejected') {
            return { color: '#eb6060' };
        } else if (params.data.statusName === 'Approved') {
            return { color: '#169404' };
        } else if (params.data.statusName === 'Partially Approved') {
            return { color: '#7cb884' };
        } else {
            return null;
        }
    }

    public formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

  public arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
  }
  

    // Capture Download & View Logs
    async createDownloadViewLog(mode: string, applicationRoute:string, downloadSize:number, module:string, description?:string, comment?:string ): Promise<number> {
        return new Promise((resolve, reject) => {
            if (mode != '') {
                let loginDetails = this.getLoginData();
                let newItems = {
                    "description": description,
                    "comment": comment,
                    "module": module,
                    "applicationId": applicationRoute,
                    "userId": JSON.parse(loginDetails)[0].id,
                    "companyId": JSON.parse(loginDetails)[0].companyId,
                    "downloadSize": String(mode).toLowerCase() === 'view' ? 0:downloadSize,
                    "mode": String(mode).toLowerCase(),
                    "createdAt": this.getFormattedTimeStamp()
                };
                this.companyService.postDownloadUsage(newItems).subscribe(res => {
                    if (res['success'] === true) {
                        resolve(null);
                    } else {
                        reject(null);
                    }
                });
            } else {
                reject(null);
            }
        });
    }


  public getS3URL(folderName: string, imageName: string, isEnvironment: boolean): any {
    let url = this.commonService.getAPI_Token().url + 'download?filter[where][token]=' + this.commonService.getAPI_Token().token + '&filter[where][filename]=' + imageName + '&filter[where][folder]=' + folderName + '&filter[where][isEnvironment]=' + isEnvironment;
    this.http.get(url, {responseType: 'text'}).subscribe(data => {
      window.open(data, "_blank");
    })
  }

}
